import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const calculatorGuard: CanActivateFn = (route, state) => {
  //Si no estas logueado, no verifica que tengas roles, intenta hacer la redireccion devolviendo true pero te lleva al SSO
  const authSrv = inject(AuthService);
  if(authSrv.isLogged()) {
    let userRoles: string[] = [];
    const router = inject(Router);
    authSrv.setUserRoles();
    userRoles = authSrv.getUserRoles();
    if (!userRoles.includes('ROLE_CALCULATOR') || userRoles.includes('ROLE_CORPORATE') || userRoles.includes('PERMISSION_NOT_VISUALIZATION')) {
      router.navigateByUrl('/home');
      return false;
    }
    return true;
  } else {
    return true;
  }
};
