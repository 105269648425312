export const environment = {
    production: true,
    version: '0.102',
    apiKeyMap: 'AIzaSyCGfW2NrY9ECU7hE6VONSG7Xk6QpIayFAQ', // NEW KEY PUMA
    //apiKeyMap:'AIzaSyDjfOjsuMXS2D_XuG3e3XFnVAufH7bBB6c',
    apiKeyMapMobile: 'AIzaSyBtSmp-pqjaer3Hfg3zdJE43z7J5EiCyGo',
    api: 'https://api-puma-stg.tercerpiso.tech/api/',
    ssoLocation: 'https://puma-websso-stg.tercerpiso.net',
    apiSSO: 'https://puma-sso-stg.tercerpiso.tech/api/',
    intervalMigratingRequest: 1000,
    oldSimulationLink: 'https://puma.ludelaba.com.ar/HuellaCarbono/HuellaCarbonoReport/simulacion',
    oldMonitoreoLink: 'https://ludela.tercerpiso.tech/Reports/DynamicReport',
    grafanaDashboardProdClientUuid: 'https://grafana.plataformapuma.com/d/f3bb8482-a5fe-4853-9305-cf9cf79909g1/agricultura-stg?orgId=1',
    grafanaDashboardProyecto: 'https://grafana.plataformapuma.com/d/f3bb8482-a5fe-4853-9305-cf9cf79909g3/dashproy?orgId=1',
    grafanaDashboardRetaaProdClientUuid: 'https://grafana.plataformapuma.com/d/b826144e-ed15-4c7c-b6d8-50349597cb7r/comparativa-retaa-prodcli?orgId=1',
    grafanaDashboardRetaaProyecto: 'https://grafana.plataformapuma.com/d/b826144e-ed15-4c7c-b6d8-50349597cb8r/comparativa-retaa-proyecto?orgId=1',
    grafanaDashboardGanaderia: 'https://grafana.plataformapuma.com/d/fa431169-6a6a-4fcb-88a1-15c36add7038/ganaderia-stg?orgId=1&var-tipo=Carne',
    showCleanData: false,
    mapServerURL: 'https://backend.plataformapuma.com:8183/cgi-bin/mapserv?',
    CO2: 1,
    CH4: 25,
    N2O: 298,
    platformApi: 'https://puma-monitoring-middleware-stg.tercerpiso.tech/api/',
    timeoutWatchDogSatellite: 2000,
    backofficeLocation: 'https://puma-backoffice-stg.tercerpiso.tech',
  };
