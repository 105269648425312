import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const platformMonitoringGuard: CanActivateFn = (route, state) => {
  const authSrv = inject(AuthService);
  if(authSrv.isLogged()) {
    let userRoles: string[] = [];
    const router = inject(Router);
    authSrv.setUserRoles();
    userRoles = authSrv.getUserRoles();
    if (!userRoles.includes('ROLE_PLATFORM_MONITORING')) {
      router.navigateByUrl('/home');
      return false;
    }
    return true;
  } else {
    return true;
  }
};
