import { Client, DefaultResponse, GanaderyCalcIn, Person, Producer, ReportObject, SoilOut, calculationModel } from 'src/app/services/domains/calculator.domain';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { Field } from './domains/biblioteca.domain';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CropEdition } from './domains/crop-edition.domain';
import { FieldCalculatorIn } from './domains/field.domain';
import { utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {

  calculationModel = new BehaviorSubject<calculationModel | undefined>(undefined);

  menuOpen = new Subject<boolean>();

  private calculationModelPrivate: calculationModel | undefined;

  constructor(private readonly httpC: HttpClient) {
    this.calculationModel.subscribe({
      next: (v) => {
        this.calculationModelPrivate = v;
      }
    });
  }

  country?: string;
  paid: boolean = false;
  setConfig(country: string, paid: boolean) {
    localStorage.setItem('localConfig', JSON.stringify({ country, paid }));
    this.country = country;
    this.paid = paid;
  }

  getConfig() {
    return {
      country: this.country,
      paid: this.paid
    }
  }

  reloadFields = new Subject<boolean>();
  makeClientProducerList(data: Person): ClientCombo[] {
    let idKey = 0;
    const out: ClientCombo[] = data.producers.flatMap((producer: Producer) => {
      return producer.client.map((client: Client) => {
        idKey++;
        return {
          idKey,
          productor: producer.name,
          uuidProducer: producer.uuid,
          uuidClient: client.uuid,
          producerID: producer.idProducer,
          oldProducerID: producer.idOldProducer,
          cliente: client.razonSocial,
          clientID: client.idClient,
          oldClientID: client.idOldClient,
          cuit: client.cuit,
          isProductor: true,
          isClient: false,
          calc_agricola: client.calc_agricola,
          calc_ganaderia: client.calc_ganaderia,
          calc_forestal: client.calc_forestal,
          calc_ganadera: client.calc_ganadera,
        };
      });
    });
    data.clients.forEach((client: Client) => {
      client.producers.forEach((producer: Producer) => {
        // ya estaba en la lista?
        if(!producer.deleted){
          const elem = out.find((c) => c.clientID === client.idClient && c.producerID === producer.idProducer);
          if (elem) {
            elem.isClient = true;
          } else {
            idKey++;
            out.push({
              idKey,
              productor: producer.name,
              producerID: producer.idProducer,
              uuidProducer: producer.uuid,
              uuidClient: client.uuid,
              cliente: client.razonSocial,
              oldProducerID: producer.idOldProducer,
              clientID: client.idClient,
              oldClientID: client.idOldClient,
              cuit: client.cuit,
              isProductor: false,
              isClient: true
            });
          }
        }
      });
    });
    return out.filter(elem => !(elem.isClient == false && elem.isProductor == true));
  }

  setClientProducerSelected(option: ClientCombo) {
    localStorage.setItem('producerClient', JSON.stringify(option));
    this.reloadFields.next(true);
    this.setProducerClientTuple(option.producerID ?? 0, option.clientID ?? 0).pipe(take(1)).subscribe(resp => {
    })
  }

  getClientProducerSelected() {
    return utils.getClientProducerSelected();
  }

  private getFieldFromStorage() {
    return JSON.parse(localStorage.getItem('scenarios')!);
  }

  saveConfigModelCalculator(model: calculationModel | undefined) {
    if (model === undefined) {
      this.calculationModel.next(undefined);
      this.calculationModelPrivate = undefined;
    } else {
      let data = {
        ...this.calculationModelPrivate!,
        ...model
      }
      this.calculationModel.next(data);
    }
  }

  saveCalculationInField(fieldID: string, key: string, model: calculationModel | undefined) {
    // let scenariosArray = this.getFieldFromStorage()
    // let field = scenariosArray.find((field: Field) => field.id === fieldID);
    // if(field){
    //   let data = {
    //     ...this.calculationModelPrivate!,
    //     ...model
    //   }
    //   field.calculations[key] = data;
    //   localStorage.setItem('scenarios', JSON.stringify(scenariosArray));
    // }else{
    //   console.error('Error: No se encontro el campo');
    // }
  }

  getCalculationSaved(fieldID: string, key: string) {
    let scenariosArray = this.getFieldFromStorage();
    let field = scenariosArray.find((field: Field) => field.id === fieldID);
    if (Object.keys(field?.calculations).length > 0 && field?.calculations.hasOwnProperty(key)) {
      this.calculationModel.next(field!.calculations[key]);
      //return field?.calculations
    } else {
      this.calculationModel.next(undefined);
      //return undefined;
    }
  }

  //--------------------------------------------//

  saveCalculationConfigSoil(idCampaign: number, idfield: number, model: SoilOut) {
    return this.httpC.put<CropEdition>(`${environment.api}calculadora/soil/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, model);
  }

  //saveCalculationConfigCrop(idCampaign: number, idfield: number, model: CropOut){
  saveCalculationConfigCrop(idCampaign: number, idfield: number, model: any) {
    return this.httpC.put<CropEdition>(`${environment.api}calculadora/crop/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, model);
  }

  saveGanaderyCalc(idCampaign: number, idfield: number, model: GanaderyCalcIn) {
    return this.httpC.put<any>(`${environment.api}calculadora/cattle-raising/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, model);

  }

  getConfigCalculation(idCampaign: number, idfield: number) {
    return this.httpC.get<DefaultResponse<calculationModel>>(`${environment.api}calculadora/calculation/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  getEmissionsByCampaignAndField(idCampaign: number, idfield: number) {
    return this.httpC.get<DefaultResponse<ReportObject>>(`${environment.api}calculadora/emmision/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  getEmmisionsByCampaignForEnvironment(idCampaign: number, idfield: number, idLot: number, idEnvironment: number) {
    return this.httpC.get<DefaultResponse<ReportObject>>(`${environment.api}calculadora/emmision/${idCampaign}/${idfield}/${idfield}/${idEnvironment}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`)
  }

  getCattleEmission(idCampaign: number, idfield: number,) {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}calculadora/cattle-raising/emmission/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }


  getFieldById(fieldID: number) {
    return this.httpC.get<DefaultResponse<FieldCalculatorIn>>(`${environment.api}map/field/${fieldID}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  getCalculatorMe() {
    return this.httpC.get<DefaultResponse<Person>>(`${environment.api}user/me`);
  }

  setProducerClientTuple(idProducer: number, idClient: number) {
    return this.httpC.post<DefaultResponse<any>>(`${environment.api}user/selected`,{idProducer, idClient});
  }

  sync(email: string) {
    return this.httpC.get<DefaultResponse<Person>>(`${environment.api}user/migrate?email=${email}`);
  }

  fullSync() {
    return this.httpC.get<DefaultResponse<Person>>(`${environment.api}user/migrate-full`);
  }

  getRetaaValues(idCampaign: number, idfield: number, data: any) {
    return this.httpC.put<DefaultResponse<any>>(`${environment.api}calculadora/reset-soil-data/${idCampaign}/${idfield}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, data);
  }

  importCalculate(idCamp: number, data: FormData) {
    return this.httpC.post<Blob>(`${environment.api}user/import/calculations/campaigns?idProducer=${this.getProducerId()}&idCampaign=${idCamp}`,
    data, { headers: { enctype: 'multipart/form-data' }, responseType: 'blob' as 'json' }
    );
  }

  exportCalculate(idCamp: number): Observable<any> {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}user/export/calculations/campaigns?idProducer=${this.getProducerId()}&idCampaign=${idCamp}&idClient=${this.getClientProducerSelected().clientID}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  exportCalculateByProject(idProject: number): Observable<any> {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}user/export/calculations/campaigns/project?idProject=${idProject}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  exportAll(idCamp: number): Observable<any> {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}user/export/calculations/campaigns/ipuma?idProducer=${this.getProducerId()}&idCampaign=${idCamp}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  getProducerId() {
    return utils.getClientProducerSelected().producerID;
  }
}
export interface ClientCombo {
  idKey: number,
  productor: string,
  producerID: number,
  oldProducerID: number;
  uuidProducer: string
  cliente: string,
  clientID: number,
  cuit: string,
  uuidClient: string
  oldClientID: number;
  isProductor: boolean,
  isClient: boolean,
  calc_agricola?: boolean
  calc_ganaderia?: boolean
  calc_ganadera?: boolean
  calc_forestal?: boolean
}
