import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ssoGuard } from './services/guards/sso.guard';
import { calculatorGuard } from './services/guards/calculator.guard';
import { simulatorGuard } from './services/guards/simulator.guard';
import { createFieldGuard } from './services/guards/create-field.guard';
import { calculatorCftGuard } from './services/guards/calculator-cft.guard';
import { productivityDashboardGuard } from './services/guards/productivity-dashboard.guard';
import { platformMonitoringGuard } from './services/guards/platform-monitoring.guard';

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [ssoGuard]
  },
  {
    path: "sustentabilidad",
    loadChildren: () => import('./pages/sustainability/sustainability.module').then(m => m.SustainabilityModule),
    canActivate: [ssoGuard]
  },
  // {
  //   path: "mapa",
  //   loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule),
  //   canActivate: [ssoGuard]
  // },
  {
    path: "calculadora",
    loadChildren: () => import('./pages/calculator/calculator.module').then(m => m.CalculatorModule),
    canActivate: [ssoGuard, calculatorGuard]
  },
  {
    path: "productividad",
    loadChildren: () => import('./pages/productividad/productividad.module').then(m => m.ProductividadModule),
    canActivate: [ssoGuard, productivityDashboardGuard]
  },
  {
    path: "calculadora-cft",
    loadChildren: () => import('./pages/calculator-cft/calculator-cft.module').then(m => m.CalculatorCftModule),
    canActivate: [ssoGuard, calculatorCftGuard]
  },
  {
    path: "analitica",
    loadChildren: () => import('./pages/analitica/analitica.module').then(m => m.AnaliticaModule),
    canActivate: [ssoGuard]
  },
  {
    path: "simulador",
    loadChildren: () => import('./pages/simulator/simulator.module').then(m => m.SimulatorModule),
    canActivate: [ssoGuard, simulatorGuard]
  },
  {
    path: "simulador-agrupador/:id",
    loadChildren: () => import('./pages/simulator-group/simulator-group.module').then(m => m.SimulatorGroupModule),
    canActivate: [ssoGuard, simulatorGuard]

  },
  {
    path: "simulador-agrupador/:id/:simuId",
    loadChildren: () => import('./pages/simulator-group/simulator-group.module').then(m => m.SimulatorGroupModule),
    canActivate: [ssoGuard, simulatorGuard]

  },
  {
    path: 'reporte/:idS',
    loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule),
    canActivate: [ssoGuard, simulatorGuard]

  },
  {
    path: "map-studio",
    loadChildren: () => import('./pages/map-studio/map-studio.module').then(m => m.MapStudioModule),
    canActivate: [ssoGuard, createFieldGuard]
  },
  {
    path: "map-studio/:id",
    loadChildren: () => import('./pages/map-studio/map-studio.module').then(m => m.MapStudioModule),
    canActivate: [ssoGuard]
  },
  {
    path: "carbon-studio",
    loadChildren: () => import('./pages/carbon-studio/carbon-studio.module').then(m => m.CarbonStudioModule),
    canActivate: [ssoGuard]
  },
  {
    path: 'certs',
    loadChildren: () => import('./pages/certs/certs.module').then(m => m.CertsModule),
    canActivate: [ssoGuard]
  },
  {
    path: "error",
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: "mantenimiento",
    loadChildren: () => import('./pages/mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule)
  },
  {
    path: "monitoreo",
    loadChildren: () => import('./pages/platform/platform.module').then(m => m.PlatformModule),
    canActivate: [ssoGuard, platformMonitoringGuard]
  },
  {
    path: "perfil",
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [ssoGuard]
  },
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
        
  ]
})
export class AppRoutingModule { }
