<main>
  <nav class="noPrint">
    <div class="navLogoBread">
      <div id="logo" class="logo-container" routerLink="/home" style="cursor: pointer;">
        <img class="logo" src="../assets/icons/logo-grande.svg" alt="">
      </div>
      <ul>
        <li class="bread-crumb" *ngFor="let path of bradcrumbUrls; let i = index">
          <ng-container *ngIf="i < bradcrumbUrls.length - 1">
            <span [routerLink]="path.url" style="cursor: pointer;">{{path.label}}</span>
          </ng-container>
          <ng-container *ngIf="i === bradcrumbUrls.length - 1">
            <span>{{path.label}}</span>
          </ng-container>
          <img *ngIf="i < bradcrumbUrls.length - 1" src="../assets/icons/greater.svg" alt="">
        </li>
      </ul>
    </div>
    <div class="navButtons">
      <div style="display: flex; align-items: end; flex-direction: column; justify-content: space-evenly;">
        <div style="display: flex; gap: 10px;">
          <button class="planButton" disabled *ngIf="migrating">Sincronizando su cuenta <span
            class="loaderMigrating"></span></button>
          <button class="planButton" *ngIf="isLogged && !users?.pay" (click)="changePlan()">Plan gratuito</button>
          <button class="planButton premium" *ngIf="!showButtonBackoffice && (isLogged && users?.pay)">Plan
            Premium</button>
          <button class="planButton" (click)="goToBackoffice()" *ngIf="isLogged && showButtonBackoffice">Acceso a
            Backoffice</button>
          <button class="billButton" (click)="openUsersModal()" *ngIf="isLogged">
            {{clientName}}
            <i class="pi pi-arrow-right-arrow-left"></i>
          </button>
        </div>
        <div style="margin-top: 8px;">
          <p routerLink="/perfil" style="cursor: pointer; font-size: 14px; color: #fff; padding: 0; margin: 0;">{{users?.email}}</p>
        </div>

      </div>


      <button class="planButton" *ngIf="!isLogged" (click)="login()">Ingresar</button>
      <!-- <button class="billButton">Ver facturacion</button> -->
    </div>
  </nav>
  <section>
    <aside id="menu" class="noPrint">
      <ul>
        <li (click)="viewMenu()" class="no-scale" [class.xactive]="open">
          <fa-icon *ngIf="!open" icon="bars" style="color: white;"></fa-icon>
          <fa-icon *ngIf="open" class="xmark" icon="xmark" style="color: white;"></fa-icon>
        </li>
        <li routerLink="/home" routerLinkActive="activo" pTooltip="{{!open ? 'Home' : ''}}" tooltipPosition="right"
          *ngIf="!maintenance">
          <img src="../assets/icons/home-empty.svg" alt="">
          <span>Inicio</span>
        </li>
        <li [class.disabled]="isGaliciaDemoUser" routerLink="/carbon-studio" routerLinkActive="activo"
          pTooltip="{{!open ? 'Carbon Studio' : ''}}" tooltipPosition="right" *ngIf="!maintenance">
          <img src="../assets/icons/icon-hc.png" alt="Icon carbon studio">
          <span>Carbon Studio</span>
        </li>
        <li [class.disabled]="isGaliciaDemoUser" pTooltip="{{!open ? 'Analitica' : ''}}" tooltipPosition="right"
          routerLink="/analitica" routerLinkActive="activo" *ngIf="isLogged && !maintenance">
          <img src="../assets/icons/bars-empty.svg" alt="Icon charts">
          <span>Analítica</span>
        </li>
        <li *ngIf=" isLogged && showAccessToProductivityDashboard && !maintenance"
          pTooltip="{{!open ? 'Tablero de Productividad' : ''}}" tooltipPosition="right" routerLink="/productividad"
          routerLinkActive="activo">
          <img src="../assets/icons/monitor.svg" alt="Icon charts">
          <span>Tab. Productividad</span>
        </li>
      </ul>
      <footer>
        <ul>
          <!-- <li (click)="logout()" *ngIf="isLogged">
            <img src="../assets/icons/logout.svg" alt="" style="margin-left: 3px">
            <span>Salir</span>
          </li> -->
          <!-- <li (click)="handleClear()" *ngIf="isLogged" pTooltip="{{!open ? 'Limpiar datos' : ''}}" tooltipPosition="right">
            <img src="../assets/icons/ban-solid.svg" alt="" style="margin-left: 3px">
            <span>Limpiar datos</span>
          </li>
          <li *ngIf="isLogged && canMigrate" pTooltip="{{!open ? 'Configuracion' : ''}}" (click)="advOpt = !advOpt" tooltipPosition="right">
            <img src="../assets/icons/gear.svg" alt="">
            <span>Configuración</span>
          </li> -->
          <li pTooltip="{{!open ? 'Soporte' : ''}}" tooltipPosition="right" (click)="openContactSupport()">
            <img src="../assets/icons/lifeGuard.svg " alt="">
            <span>Soporte</span>
          </li>
          <p-divider *ngIf="isLogged" class="aside-divider"></p-divider>
          <li routerLink="/perfil" class="aside-user" [class.activo]="open" *ngIf="isLogged"
            pTooltip="{{!open ? 'Mi cuenta' : ''}}" tooltipPosition="right">
            <p-avatar image="../assets/field.png" size="normal" shape="circle"></p-avatar>
            <div class="aside-user-text">
              <p>Mi cuenta</p>
              <p (click)="logout()">Cerrar sesión <img src="../assets/icons/logout.svg" alt=""></p>
            </div>
          </li>
          <li (click)="logout()" *ngIf="isLogged && !open" pTooltip="Cerrar sesión" tooltipPosition="right">
            <img src="../assets/icons/logout.svg" alt="" style="margin-left: 3px">
          </li>
        </ul>
      </footer>
    </aside>
    <article [class.whitout-footer]="inMap">
      <router-outlet></router-outlet>
      <footer id="footer" *ngIf="!inMap" class="noPrint">
        <div class="content">
          <span [class.minimize]="open">© 2023 Plataforma Puma S.A.</span>
          <div class="icons">
            <button (click)="sync()" *ngIf="isLogged && advOpt" class="primary">Syn Usr</button>
            <button (click)="fullSync()" *ngIf="isLogged && advOpt">Full Sync</button>
            <!-- <app-patch-notes></app-patch-notes> -->
            <a target="_blank" href="https://www.facebook.com/puma.monitoreo.agricola">
              <img src="../assets/icons/facebook.svg" alt="">
            </a>
            <a target="_blank" href="https://twitter.com/plataformapuma">
              <img src="../assets/icons/twitter.svg" alt="">
            </a>
            <a target="_blank" href="https://www.instagram.com/plataformapuma/">
              <img src="../assets/icons/instagram1.svg" alt="">
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/plataforma-puma-20a2301ab/">
              <img src="../assets/icons/linkedin1.svg" alt="">
            </a>
          </div>
        </div>
      </footer>
    </article>
  </section>
</main>

<div class="loadingScreen" *ngIf="loadingScreen">
  <img style="filter: invert(100);width: 20%;" src="assets/icons/logo-grande.svg">
  <div class="loaderBalls"></div>
</div>

<p-dialog class="usersModal" header="Seleccionar productor" [(visible)]="usersModal" [modal]="true" [draggable]="false"
  [resizable]="false">
  <div class="formGroup" style="margin-bottom: 8px;">
    <i class="pi pi-search"></i>
    <input type="text" placeholder="Buscar" (input)="searchFilterApply($event.target)">
  </div>
  <p-table [value]="productorClientCombFiltered" selectionMode="single" [(selection)]="selectedProducerClient"
    dataKey="idKey" [tableStyle]="{'width': '50vw'}">
    <ng-template pTemplate="header">
      <tr>
        <th>Cliente</th>
        <th>Productor</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr [pSelectableRow]="user">
        <!-- <td>{{user.idKey}}</td> -->
        <td>
          {{user.cliente}}
          <i *ngIf="user.isClient" class="pi pi-star-fill goldStar" style="color:#e9c54e"></i>
        </td>
        <td>
          {{user.productor}}
          <i *ngIf="user.isProductor" class="pi pi-star-fill goldStar" style="color:#e9c54e"></i>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <button class="primary" (click)="saveProducerClientSelection(true)">Seleccionar</button>
  </ng-template>
</p-dialog>

<p-dialog class="userModal" [(visible)]="signupModal" [style]="{'max-width': '35%'}" [modal]="true" [draggable]="false"
  [closable]="false" [resizable]="false">
  <app-dataform (done)="signupDone()"></app-dataform>
</p-dialog>

<app-premium></app-premium>
<app-migrando #migrandoComponent [(migrando)]="migrando" [(migrationError)]="migrandoErrors"></app-migrando>


<p-dialog header="Usuario sin permiso" [(visible)]="openSupportModal" [modal]="true" [closable]="true"
  [draggable]="false" [resizable]="false" [contentStyle]="{'overflow':'visible','width': '600px'}">
  <div class="centered" style="text-align: center;">
    <img src="../../../assets/warning.png" alt="Confirm warning" style="width: 60px;margin: 0 auto;display: block;">
    <p>No posee el rol para utilizar esta herramienta, si desea puede contactar a soporte para solicitar permiso.</p>
    <button class="primary" (click)="requestPermission()">
      <span *ngIf="!sendingTicket">Contactar a soporte</span>
      <div class="loaderBars" *ngIf="sendingTicket"></div>
    </button>
  </div>
</p-dialog>

<p-dialog header="Contacto a soporte" (onHide)="resetSupportForm()" [(visible)]="openSupportForm" [modal]="true"
  [closable]="true" [draggable]="false" [resizable]="false" [contentStyle]="{'overflow':'visible','width': '600px'}">
  <form action="" [formGroup]="formSupport" (ngSubmit)="sendSupportMessage()">
    <div class="form-container">
      <div class="name-email">
        <div class="input-cont">
          <label for="name">Nombre</label>
          <input type="text" id="name" name="name"
            [class.invalid]="formSupport.get('name')?.invalid && formSupport.get('name')?.touched"
            formControlName="name">
        </div>
        <div class="input-cont">
          <label for="email">Email</label>
          <input type="text" id="email" name="email"
            [class.invalid]="formSupport.get('email')?.invalid && formSupport.get('email')?.touched"
            formControlName="email">
        </div>
      </div>
      <div class="textarea-cont">
        <label for="message">Mensaje</label>
        <textarea id="message" name="message"
          [class.invalid]="formSupport.get('message')?.invalid && formSupport.get('message')?.touched"
          formControlName="message"></textarea>
      </div>
    </div>

    <small *ngIf="!formSupport.valid">*Todos los campos son obligatorios.</small>

    <div class="centered" style="text-align: center;">
      <button class="primary" [class.disabled]="!formSupport.valid">
        <span *ngIf="!sendingTicket">Contactar a soporte</span>
        <div class="loaderBars" *ngIf="sendingTicket"></div>
      </button>
    </div>
  </form>
</p-dialog>

<p-toast></p-toast>
